@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.chat-comp{
  position: relative;
  .flex{
    display: flex;
    > div{
      margin-right: 20px;
    }
  }
  .chat-header{
    display: flex;
    justify-content: space-between;
    .buttons{
      display: flex;
      margin: 0 0 20px;
      .button-element{
        margin: 0;
        margin-left: 20px;
        width: auto;
        button{
          padding-right: 40px;
        }
      }
    }
    @media screen and (max-width: $mobile-width) {
      .buttons{
        .button-element{
          width: 40px;
          button{
            color: rgba(0,0,0,0s);
          }
        }
      }
    }
  }
  .toggles{
    // max-width: 350px;
    display: flex;
    // background-color: blue;
    // max-width: 100%;
    // overflow: hidden;
    // width: auto;
  }
  .prompt-div{
    // border: 1px solid black;
    margin-top: 12px;
    margin-bottom: 12px;
    textarea{
      margin: 0;
      background-color: #333;
      color: yellow;
      font-size: 14px;
      line-height: 16px;
      max-width: 100%;
    }
    .defaults{
      // border: 1px solid blue;
      label{
        margin-bottom: 4px;
        font-size: 14px;
      }
      .copy-input{
        max-width: 100%;
        margin: 0;
        input, textarea{
          font-size: 12px;
          line-height: 16px;
          background-color: #333;
          color: #FFF;
        }
      }
    }
  }
  
  
  .chat-container{
    display: flex;
    .left{
      width: 200px;
      width: 200px;
      .switch-toggle{
        margin: 0;
      }
      .left-items{
        margin: 20px 0;
        .item{
          margin: 0 0 8px;
          border-radius: 5px;
          padding: 4px 12px;
          &:hover,
          &.active{
            cursor: pointer;
            background-color: $primary-color;
            color: #FFF;
          }
          >div{
            font-size: 15px;
            line-height: 18px;
          }
          >span{ 
            display: block;
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
    }
    @media screen and (max-width: $mobile-width) {
      flex-flow: column;
      .left{
        width: auto;
      }
    }
    .center{
      padding: 20px;
      background-color: rgba(240,240,240,1);
      // background-color: #DDD;
      border-radius: 3px;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,.15);
      margin-left: 20px;
      flex: 1;
      .carousel-header{
        margin-top: 0px;
        border-radius: 8px;
        background-color: #FFF;
        .items{
          > div{
            
            flex: 0 ;
          }
        }
      }
    }
    @media screen and (max-width: $mobile-width) {
      .center{
        padding: 12px;
        margin-left: 0px;
      }
    }
  }
  
  .convo{
    .convo-list{
      .convo-message{
        margin: 0 0 20px;
        textarea{
          margin: 0;
        }

        .buttons{
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
            &.up-down{
              .button-element{
                margin: 0 8px;
              }
            }
          }
          .button-element{
            width: 40px;
          }
        }
        &.Me{
          margin-left: 25%;
          
        }
        &.Them{
          margin-right: 25%;
          .buttons{
            flex-flow: row-reverse;
          }
        }
      }
    }
  }

  .add-button{
    max-width: 160px;
    margin: 0 0 20px;
    button{
      padding-right: 40px;
    }
  }
  .bottom-buttons{
    display: flex;
    justify-content: space-between;
    >div{
      .button-element{
        button{
          padding-right:40px;
        }
      }
    }
  }
}

