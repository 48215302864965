@import '../../css/styles/colors';
@import '../../css/styles/variables';

.footer{
  background-color: $footer-background-color;
  color: $footer-font-color;
  .footer-sub{
    position: relative;
    padding: 40px 20px 20px;
    margin: 0 auto;
    max-width: $footer-max-width;
    a{
      color: $footer-font-color;
    }
    .footer-logo{
      margin: 0px 0 20px;
      display: flex;
    }
    .footer-blocks{
      margin: 40px 0;
      display: flex;
      .block{
        flex: 1;
        display: flex;
        flex-flow: column;
        .header{
          font-size: 20px;
          margin: 16px 0;
          font-weight: 500;
        }
        .links{
          display: flex;
          flex-flow: column;
          > a{
            margin: 0 0 12px;
            font-size: 14px;
            color: $footer-font-secondary-color;
          }
        }
        &.community{
          
          .links {
            flex-flow: row;
            flex-wrap: wrap;
            a {
              margin: 4px;
              padding: 6px;
              height: 40px;
              width: 40px;
              transition: all 0.3s;
              border-radius: 3px;
              svg {
                height: 100%;
                width: 100%;
                .real-path {
                  fill:$footer-font-secondary-color;
                } 
                .bg-path{
                  fill:$footer-background-color;
                }
              }
              &:hover{
                background-color: #FFF;
                svg{
                  .real-path{
                    fill: $primary-color;
                  }
                  .bg-path{
                    fill: #fff;;
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .footer-legal{
      text-align: center;
      margin: 40px 0 0 0;
      color: $footer-font-secondary-color;
      > div{
        margin: 0px 0;
        font-size: 14px;
        line-height: 24px;
      }
    }
    @media screen and (min-width: $desktop-width) {
      .language-button{
        left: 20px;
        bottom: 28px;
        position: absolute;
      }
    }
    .language-button{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 3px;
      .icon{
        height: 36px;
        width: 36px;
        padding: 6px;
        svg {
          .real-path{
            fill:#FFF;
            transition: 0.3s;
          }
        }
      }
      .text{
        padding: 0 8px 0 0;
      }
      &:hover{
        background-color: #FFF;
        .icon{
          svg .real-path{
            fill: $primary-color;
          }
        }
        .text{
          color: $primary-color;
        }
      }
    }
    .footer-message{
      opacity: 0.8;
      font-size: 12px;
      margin: 20px 0;
      text-align: center;
    }
  }
  .sandbox-note{
    margin: 40px 0;
    padding: 20px;
    border: 1px solid #FFF;
    border-radius: 8px;
    .sandbox-note-header{
      margin: 0 0 20px;
      display: flex;
      align-items: center;
      .text{
        font-size: 18px;
        flex: 1;
        text-align: center;
      }
      svg .real-path{
        fill:#FFF;
      }
    }
    p{
      font-size: 14px;
      &:last-of-type{
        margin-bottom: 0px;
      }
    }
  }
}
.sandbox .footer {background-color: $sandbox-footer-background-color;}
.sandbox .footer .footer-sub .footer-blocks .block.community .links a{
  svg {
    .real-path { fill:$footer-font-secondary-color;} 
    .bg-path{ fill:$sandbox-footer-background-color;}
  }
  &:hover svg{
    .real-path{ fill: $sandbox-primary-color;}
    .bg-path{ fill: #fff;;}
  }
}
.sandbox .footer .footer-sub .language-button{
  &:hover{
    .icon svg .real-path{ fill: $sandbox-primary-color; }
    .text{ color: $sandbox-primary-color;}
  }
}


@media screen and (max-width: $mobile-width) {
  .footer{
    display: flex;
    .footer-sub{
      margin: 0;
      width: 100%;
      .footer-blocks{
        flex-flow: column;
      }
    }
  }
}