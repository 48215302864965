@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$header-height: 60px;
$button-margin: 8px;

$widthToggle: 780px;

.home{
  .width-container{
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
  }
  > .header{
    background-color: #FFF;
    height: $header-height;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
    .sub{
      height: 100%;
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $mobile-width) {
        padding: 0px;
      }
      .logo{
        display: flex;
        height: 100%;
        .icon{
          height: 100%;
          width: $header-height;
          padding: 10px;
          svg{
            height: 100%;
            width: 100%;
          }
        }
        .title{
          color: $primary-color;
          font-size: 24px;
          font-weight: 700;
          font-style: italic;
          line-height: $header-height;
        }
      }
      .buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: $mobile-width) {
          .my-account,
          .create-account,
          .sign-in{
            display: none;
          }
        }
        
        
      }
    }
  }
  .clear-buttons{
    a{
      height: 40px;
      margin: $button-margin;
      display: flex;
      background-color: #FFF;
      border-radius: 3px;
      color: #333;
      font-weight: 700;
      &:hover{
        text-decoration: none;
        box-shadow:inset 0px 0px 250px 0px rgba(0,0,0,.1);
      }
      &:active{box-shadow:inset 0px 0px 250px 0px rgba(0,0,0,.2);}
      .icon{
        padding: 8px;
        height: 40px;
        width: 40px;
        svg{
          height: 100%;
          width: 100%;
        }
      }
      .label{
        padding: 0 0 0 12px;
        line-height: 40px;
      }
    }
  }
  section {
    // border: 1px solid blue;
    padding: 40px;
    @media screen and (max-width: $mobile-width) {
      padding: 20px;
    }

    &.financials,
    &.historical-prices,
    &.our-mission{
      background-color: $primary-color;
    }
    &.historical-prices,
    &.our-mission{
      color: #FFF;
    }

    &.intro{
      .sub{
        display: flex;
        @media screen and (max-width: $mobile-width) {
          flex-flow: column;
        }
        .text{
          flex: 8;
          display: flex;
          justify-content: center;
          align-items: center;
          .text-sub{
            padding: 20px;
            .try-it-div{
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }
        .spacer{
          flex:1;
        }
        .image{
          position: relative;
          flex: 11;
          @media screen and (max-width: $mobile-width) {
            flex: 4;
            padding: 0 12%;
          }
          > div{
            padding-bottom: 70%;
            // background-image: url('../../../css/imgs/pic-stonkify-home.png');
            background-repeat: no-repeat;
            background-position: 50% 100%;
            background-size: 100%;
            height: 100%;
            @media screen and (max-width: $mobile-width) {
              height: 50%;
            }
          }
        }
      }

    }
    &.financials{
      padding: 40px;
      @media screen and (max-width: $mobile-width) {
        padding: 20px;
      }
      @media screen and (max-width: 460px) {
        padding: 8px 8px 20px;
      }
      .sub{
        display: flex;
        flex-direction: column;
        .financials-container{
          display: flex;
          flex-direction: row-reverse;
          @media screen and (max-width: $mobile-width) {
            flex-direction: column;
          }
          > .text{
            padding: 12px;
            flex: 10;
            color: #FFF;
          }
          > .chart{
            flex: 12;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 3px 8px 0px rgba(0,0,0,.5);
            
            > .chart-shadow{}
            > .chart-sub{
              max-height: 440px;
              height: 100%;
              width: 100%;
              border-radius: 3px;
              box-shadow: 0px 3px 8px 0px rgba(0,0,0,.5);
              overflow: hidden;
            }
            
          }
          > .spacer{
            flex: 1;
          }
        }
      }
    }
    

    &.new-here{
      padding-right: 8px;
      padding-left: 8px;
      display: flex;
      justify-content: center;
      text-align: center;
      > div{
        display: inline-block;
        .buttons{
          display: flex;
          flex-direction: column;
          
          .clear-buttons{
            display: flex;
            justify-content: center;
          }
        }

      }
    }
    
  }
  .try-it{
    margin: 8px;
    padding: 12px 36px;
    border-radius: 3px;
    display: inline-block;
    background-color: blue;
    text-decoration: none;
    background-color: $primary-color;
    color: #FFF;
    &:hover{box-shadow:inset 0px 0px 250px 0px rgba(0,0,0,.1);}
    &:active{box-shadow:inset 0px 0px 250px 0px rgba(0,0,0,.2);}
    > span{
      font-style: italic;
      font-weight: 100;
      margin: 0 0 0 8px;
    }
  }

  

  .footer{
    display: flex;
    flex-direction: column;
    padding: 48px 24px;
    background-color: $primary-color;
    color: #FFF;
    a{
      color: #FFF;
    }
    .footer-main{
      display: flex;
      .logo{
        height: 40px;
        width: 40px;
        svg{
          .real-path{
            fill: #FFF;
          }
        }
      }
      .links{
        margin: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        a{
          padding: 8px;
          margin: 0 8px;
        }
      }
      @media screen and (max-width: $widthToggle) {
        flex-direction: column;
        .links{
          margin: 20px 0;
          flex-direction: column;
          align-items: flex-start;
          a{
            padding: 8px 0;
            margin: 8px 0;
          }
        }
      }
    }
    .share{
      margin: 20px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      a{
        overflow: hidden;
        border-radius: 3px;
        margin: 0 8px;
        display: block;
        height: 32px;
        width: 32px;
        .text{
          opacity: 0;
          font-size: 0;
        }
        .icon{
          border-radius: 3px;
          width: 100%;
          height: 100%;
          svg{
            width: 100%;
            height: 100%;
            .real-path{
              fill: #FFF;
            }
          } 
        }
      }
    }
    .company{
      margin: 12px 0px 0;
    }
    

  }
}