@import '../../../css/styles/colors';

.developer-comp{

  .carousel-header > .items > div{
    flex: 0;
  }

  .databases,
  .tables{
    margin: 0 0 12px;
    display: flex;
    > div {
      cursor: pointer;
      border-radius: 3px;
      padding: 8px;
      margin-right: 8px;
      &:last-of-type{margin-right: 0;}
      &:hover,
      &.active{
        color: #FFF;
        background-color: $primary-color;
      }
    }
  }
  textarea{
    font-size: 12px;
    margin: 0px;
  }
}