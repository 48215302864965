
@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.modal :first-child.user-modal{
  overflow: auto;
  max-height: 95vh;
  > .modal-content{
    padding: 0;
  }
}
.user-modal{
  .carousel-header{
    padding-right: 40px;
    margin: 0;
    .items > div {
      padding: 0 20px;
      flex: 0 1;
      width: auto;
    }
  }
  .carousel-container{
    background-color: #e4edf6;
    > div{
      padding: 20px;
    }
  }
    
  .profile{
    .item{
      input{ margin-top: 4px; }
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .modal :first-child.user-modal{
    max-width: 100vw;
  }
}

@media screen and (min-width: 1200px) {
  .modal :first-child.user-modal{
    max-width: 1200px;
  }
}