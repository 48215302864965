
@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.test-two{
  
  .green-screen{
    display: flex;
    padding: 50px;
    background-color: greenyellow;
    .swiperino-logo{
      width: auto;
      border-radius: 50px;
      display: flex;
      background-color: #FFF;
      align-items: center;
      padding: 8px 20px;
      img{
        margin: 0 0 0 12px;
        height: 60px;
        width: 60px;
      }
      .text{
        margin: 0 20px 0 20px;
        font-weight: 800;
        color: $primary-color;
        font-size: 60px;
      }
    }
  }
  
}