

.prompts-comp{

  .center-div-outer .center-div-middle > :first-child.modal-main{
    max-width: 840px;
  }

  textarea{
    padding: 4px;
    margin: 4px;
    background-color: #333;
    color: yellow;
    font-size: 12px;
    line-height: 16px;
  }
  .messages-list{
    .message{
      margin: 0 0 20px 0;
      &:last-of-type{ margin-bottom: 0px; }
      textarea{
        padding: 8px;
        line-height: 20px;
        margin: 0px;
      }
    }
  }

  .buttons{
    textarea{
      padding: 8px;
      line-height: 20px;
      margin: 0px;
    }
    > div{
      margin: 20px 0;
      &:last-of-type{margin-bottom: 0px;}

      .textarea-div{
        display: flex;
        align-items: flex-end;
        .button-element{
          margin-left: 12px;
          max-width:  120px;
          button{
            padding-right: 40px;
          }
        }
      }
    }
    // border: 1px solid black;
  }
}