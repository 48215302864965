.toggle-server-comp{
  padding: 20px;
  border-radius: 12px;
  background-color: #f3f7fc;;
  .button-element{
    width: auto;
  }
  .toggle-server-header{
    display: flex;
    justify-content: space-between;
    .add-dawi-server-button{
      button{
        padding-right: 40px;
      }
    }
  }
  .buttons{
    display: flex;
    .button-element{
      button{
        padding-right: 40px;
      }
    }
  }
  .servers{
    margin: 20px 0;
    .server{
      display: flex;
      margin-bottom: 12px;
      &:last-of-type{margin-bottom: 0;}
      .server-details{
        display: flex;
        input{
          margin-bottom: 0;
        }
        textarea{
          margin-bottom: -4px;
        }
      }
      .buttons{
        margin-left: 20px;
        display: flex;
        align-items:flex-end;
        justify-content: flex-end;
      }
      &.create{
        border: 1px solid red;
      }
    }
  }
  
}