@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

$main-padding: 20px;

.table-element{
  display: flex;
  .copy-input{
    margin: 0;
  }
  .toolbar{
    .button-element{
      width: 40px;
      button{
        color: rgba(0,0,0,0);
      }
    }
  }
  .pointer{
    cursor: pointer;
  }
  .clickable{
    cursor: pointer;
    &:hover{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.15);}
    &:active{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.3);}
  }
  .left-block,.side-items-filter{
    &:not(.hide){
      width: 140px;
      margin: 0 20px 0 0;
      flex-flow: column;
    }
    
    .left-block-item:not(.custom){
      position: relative;
      border-radius: 3px;
      margin: 0 0 8px;
      padding: 8px;
      &:hover,
      &.active{
        cursor: pointer;
        background-color: $primary-color;
        color: #FFF;
      }
    }
  }
  .side-items-filter{
    &:not(.hide){
      width: 100%;
      margin: 0;
    }
  }
  .center-block{
    position: relative;
    flex: 1;
    .loading-overlay{
      &:not(.hide){
        display: flex;
      }
      justify-content: center;
      align-items: center;
      height: calc(100% - 70px);
      width: 100%;
      background-color: rgba(0,0,0,.1);
      z-index: 75;
      top: 70px;
      left: 0px;
      position: absolute;
    }

    .search-bar-buttons-left{
      display: flex;
    }
    .search-bar-container{
      flex: 1;
    }
    .search-bar-buttons-right{
      display: flex;
      .inner-search-bar-buttons-center{
        flex: 1;
        select{
          margin: 0;
          width: 100%;
        }
      }
      .inner-search-bar-buttons-right{
        display: flex;
      }
      @media screen and (min-width: $desktop-width) {
        .inner-search-bar-buttons-left,
        .inner-search-bar-buttons-center{
          display: none;
        }
      }
    }

    .search-block{
      margin: 0 0 0px;
      .results-count{
        padding:8px;
        font-size: 12px;
      }
      > form {
        &:not(.hide){
          display: flex;
        }
        .search-conditions{
          flex: 1;
          &.singular:not(.hide){
            display: flex;
            input[type=text]{
              flex: 1;
              margin: 0;
            }
          }
          .data-range{
            flex: 1;
            display: flex;
            .date-epoch-input{
              margin: 0;
            }
            .spacer{
              width: 40px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &.multi:not(.hide){
            padding: 1px;
            border: 1px solid rgba(220,220,220,1);
            background-color: #FFF;
            border-radius: 3px;
            display: flex;
            flex-wrap: wrap;
            max-height: 40px;
            min-height: 40px;
            overflow-y: auto;
            cursor: pointer;
            &:hover{border-color: rgba(200,200,200,1);}
            &:active{border-color: rgba(180,180,180,1);}
            .search-conditon-group{
              position: relative;
              display: flex;
              flex-wrap: wrap;
              &:not(:first-of-type){
                margin: 0 0 0 20px;
                &::before{
                  line-height: 20px;
                  height: 20px;
                  width: 20px;
                  text-align: center;
                  font-size: 10px;
                  top: 0px;
                  left: -20px;
                  position: absolute;
                  content: "OR"
                }
              }
              .search-condition{
                background-color: rgba(200,200,200,1);
                font-size: 12px;
                margin: 1px;
                padding: 1px 2px;
                border-radius: 2px;
                height: 16px;
              }
            }
            

          }
        }
        
        select{
          margin: 0 0 0 12px;
        }
        .button-element{
          margin: 0 0 0 12px;
          &.filter{
            margin: 0 12px 0 0;
          }
          width: 40px;
          button,input{
            color: rgba(0,0,0,0);
          }
          &.add-button{
            width: auto;
            button,input{
            padding-right: 40px;
              color: #FFF;
            }
          }
        }
      }
    }
    .main-block{
      .content-block{
        table{
          width: 100%;
          border-collapse: collapse;
          .header{
            top: -$main-padding;
            position: sticky;
            z-index: 50;
            box-shadow: 0px 8px 8px -4px rgba(0,0,0,.1);
            background-color: #f3f7fc;
            th:not(.ignore){
              cursor: pointer;
              &:hover{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.15);}
              &:active{box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.3);}

              &.order-asc,
              &.order-desc{
                position: relative;
                padding-right: 20px;
              }
              &.order-asc{
                &::before{
                  position: absolute;
                  top: calc(50% - 15px);
                  right: 2px;
                  height: 0px;
                  width: 0px;
                  border-bottom: 10px solid rgba(60,60,60,1);
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 10px solid transparent;
                  content:"";
                }
              }
              &.order-desc{
                &::before{
                  position: absolute;
                  top: calc(50% - 5px);
                  right: 2px;
                  height: 0px;
                  width: 0px;
                  border-bottom: 10px solid transparent;
                  border-left: 10px solid transparent;
                  border-right: 10px solid transparent;
                  border-top: 10px solid rgba(60,60,60,1);
                  content:"";
                }
              }
            }
          }
          th,td{
            padding: 2px 4px;
          }
          th{
            height: 40px;
          }
          td{
            input{
              margin: 0;
            }
            min-height: 28px;
          }
          tr{
            &:hover:not(.header){box-shadow: inset 0px 0px 250px 0px rgba(0,0,0,.15);}
          }
        }
      }
      .label-active,.label-inactive{
        height: 24px;
        width: 24px;
        color: rgba(0,0,0,0);
        font-size: 0px;
        display: inline-block;
        padding: 8px;
        border-radius: 4px;
        background-color: rgba(200,200,200,1);
      }
    }
  }
  .no-results{
    background-color: #FFF;
    padding: 20px;
  }
  .footer-block{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    box-shadow: 0px -8px 8px -4px rgba(0,0,0,.1);
    // bottom: -$main-padding;
    bottom: 0px;    
    position: sticky;
    background-color: #f3f7fc;
    border-radius:  0 0 3px 3px;
    z-index: 25;
  }
  .table-search-advanced-modal{
    &:first-child{
      overflow: auto;
      max-height: 80vh;
      max-width: 650px;
    }
    .table-search-advanced-modal-main{
      .search-conditions{
        display: flex;
        flex-flow: column;
        .action-lock{
          margin: 0 8px 0 0;          
          line-height: 40px;
        }
        .search-condition-group{
          display: flex;
          flex-flow: column;
          .button-div {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            margin: 0 0 12px;
            .button-element{
              margin: 0 20px 0 0px;
              max-width: 100px;
              button{
                padding-right: 40px;
              }
            }
            .or-div:not(.hide){
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              .line{
                height: 1px;
                flex: 1;
                background-color: rgba(220,220,220,1);
              }
              .text{
                padding: 0 12px;
                font-size: 10px;
              }
            }
          }
          .search-condition{
            position: relative;
            display: flex;
            margin: 0 0 12px;
            &:not(:first-of-type)::before{
              text-align: center;
              width: 100%;
              top: -12px;
              height: 12px;
              line-height: 12px;
              font-size: 10px;
              position: absolute;
              content:"AND";
              color: rgba(0,0,0,.75);
            }
            input{
              flex: 1;
              margin: 0 8px 0 0;
            }
            select{
              margin: 0 8px 0 0;
              &.value-select{
                width: 100%;
              }
            }
            .button-element {
              max-width: 40px;
              button{
                color: rgba(0,0,0,0);
              }
            }
          }
        }
      }
    }
  }
  .filter-order-by{
    
    .filter-order-by-box{
      display: flex;
      select{
        flex: 1;
      }
      .asc-desc{
        display: flex;
        > span{
          margin-left: 12px;
          padding: 0 12px;
          display: block;
          line-height: 40px; height: 40px;
          border-radius: 4px;
          &:hover,&.active{
            cursor: pointer;
            background-color: $primary-color;
            color: #FFF;
          }
        }
      }
    }
  }
  .side-items-filter{
    .line{
      margin: 12px 0;
      height: 1px;
      width: 100%;
      background-color: rgba(220,220,220,1);
    }
  }
}
.sandbox .table-element .left-block .left-block-item:not(.custom){
  &:hover,&.active{background-color: $sandbox-primary-color;}
  .filter-order-by .filter-order-by-box .asc-desc {
    > span {
      &:hover,&.active{
        background-color: $sandbox-primary-color;
      }
    }
  }
}
// .table-element .center-block .main-block .content-block table td



@media screen and (min-width: $desktop-width) {
  .table-element{
    .left-block{
      display: flex;
    }
    table{
      background-color: #FFF;
      td{
        text-align: right;
      }
    }
    .search-conditions{
      select{
        max-width: 120px;
      }
    }
    .search-bar-buttons-right .inner-searcg-bar-buttons-left{
      display: none;
    }
  }
}


@media screen and (min-width: $mobile-width) {
  .table-element{
    .toolbar{
      display: none;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .table-element{
    .left-block{
      display: none;
    }
    thead{
      display: none;
    }
    table, table tbody, table tr, table td{
      display: block;
      width: 100%;
    }
    table tr{
      border-radius: 3px;
      background-color: #FFF;
      margin-bottom: 8px;
      &:last-of-type{margin-bottom: 0px;}
    }
    table td{
      margin: 0 0 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      text-align: right;
    }
    table td:before{
      text-align: left;
      max-width: 100px;
      font-size: 12px;
      margin: 0 0 2px;
      left: 0px;
      position: relative;
      content:  attr(data-label);
    }

    .search-block{
      .search-bar-buttons-left{ 
        &.desktop{
          display: none;
        }

      }
      .search-bar-buttons-right{
        margin: 0 0 12px;
        .inner-searcg-bar-buttons-left{
          flex: 1;
          display: flex;
          select{
            margin: 0;
            flex: 1;
          }
        }
      }
      form{
        flex-flow: column-reverse;
        .search-bar-container{
          select{
            display: none;
          }
        }
      }
    }
    .search-conditions{
      select{
        padding: 0;
        // max-width: 60px;
      }
    }
  }
}