
@import '../../../css/styles/colors';


.entry-comp{
  margin-top: 30px;
  .center-div-outer .center-div-middle > :first-child{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .center-div-outer:last-of-type .center-div-middle > :first-child{
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .center-div-outer .center-div-middle > :first-child.merchant-ref{
    border: 1px solid #2c97f2;
    background-color: #b3d6f5;
    p{margin: 0;}
  }
  .sandbox-note{
    margin: 0 20px 12px;
    padding: 20px;

    background-color: rgba(255,125,0,0.1);
    border: 1px solid orange;
    border-radius: 8px;
    .sandbox-note-header{
      margin: 0 0 20px;
      display: flex;
      align-items: center;
      .text{
        font-size: 18px;
        flex: 1;
        text-align: center;
      }
    }
    p{
      font-size: 14px;
      &:last-of-type{
        margin-bottom: 0px;
      }
    }
  }
}

.entry-login,
.entry-create-account{
  height: 100%;
}

.center-div-outer .center-div-middle > .entry:first-child{
  padding: 0px;
  transition: padding 0.3s;
}

.entry{
  margin-top: 20px;
  margin-bottom: 20px;
  .carousel-header{
    margin: 0px;
  }
  .entry-header{
    padding: 20px 0;
    color: rgba(0,0,0,0);
    height: 88px;
    display: flex;
    justify-content: center;
    svg{
      height: 100%;
      .real-path{
        fill: $primary-color;
      }
    }
    .logo-text{
      line-height: 48px;
      display: block;
      color: $primary-color;
      font-size: 24px;
      font-weight: 700;
      font-style: italic;
      padding: 0 12px;
    }
  }  
  .url-check{
    border: 1px solid black;
  }
  .sns-button-space{
    height: 18px;
  }
  .recaptcha{
    max-width: 200px;
  }
  .sign-in-slide,
  .sign-up-slide{
    padding: 20px 20px 0;
  }
  .entry-sns{
    padding: 0 20px 20px;
  }
}

@media screen and (max-width: 342px) {
  .entry{
    width: 100%;
    .sign-in-slide,
    .sign-up-slide{
      padding: 20px 8px 0;
    }
  }
}