@import '../../../css/styles/colors';

$base-margin-right:8px;
$dot-width: 24px;
$dot-height: 8px;

.pagination{
  ul{
    margin: 0;
    display: flex;
    li{
      position: relative;
      margin: 0 $base-margin-right 0 0;
      text-align: center;

      > div{
        cursor: pointer;
        border-radius: 3px;
        border: 1px solid $primary-color;
        line-height: 40px;
        height: 40px;
        width: 40px;
        &:hover{ box-shadow: inset 0 0 250px 0 rgba(0,0,0,.15);}
        &:active{ box-shadow: inset 0 0 250px 0 rgba(0,0,0,.3);}
      }
      &.active > div { background-color: $primary-color; color: #FFF;}

      &.first{
        margin-right: $base-margin-right + $dot-width;
        &::after{
          position: absolute;
          content: "...";
          height: $dot-height;
          bottom: $dot-height;
          line-height: $dot-height;
          width: $dot-width;
          right: - ($dot-width+$base-margin-right - 4px);
        }
      }
      &.last{
        margin-left: $dot-width;
        &::before{
          position: absolute;
          content: "...";
          height: $dot-height;
          bottom: $dot-height;
          line-height: $dot-height;
          width: $dot-width;
          left: - ($dot-width+$base-margin-right - 4px);
        }
      }
    }
  }
}
.sandbox .pagination ul li{
  > div{
    border-color: $sandbox-primary-color;
  }
  &.active > div { background-color: $sandbox-primary-color;}
}