@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.logo-c{
  color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 24px;
  .dawipay{ margin: 0 4px 0 0;}
  .admin{ margin: 0 0 0 4px;}
  @media screen and (min-width: $desktop-width) {
    &.tiny-not-mobile{
      font-size: 11px;
      .dawipay,.admin{ margin: 0 0px;}
      flex-wrap: wrap;
      line-height: 11px;
    }
  }
  &.large{
    font-size: 32px;
  }
  &.white{
    color: #FFF;
  }
}

.sandbox{
  .logo-c{ 
    color: $sandbox-primary-color; 
    .icon svg .bg-path{fill: $sandbox-primary-color;}
  }
}
.logo-c{
  &.white{
    color: #FFF;
  }
}