

.search-comp{
  .copy-input{
    border-width: 0px;
    input{
      padding-left: 8px;
      font-size: 15px;
    }
  }
  .volume{
    input{
      max-width:1280px;
    }
  }
  .language {
    input{
      max-width: 80px;
    }
  }
  .carousel-header{
    background-color: #FFF;
  }
  .left{
    text-align: left;
  }
  .box{
    display: flex;
    textarea{
      flex: 1;
    }
    .button-element{
      margin-left: 20px;
      width: auto;
      button{
        padding-right: 40px;
      }
    }
  }
  .buttons{
    display: flex;
    justify-content: flex-end;
    .button-element{
      width: 40px;
    }
    &.left .button-element{
      margin-right: 12px;
    }
    &.right .button-element{
      margin-left: 12px;
    }
    .new-article{
      width: auto;
      button{
        padding-right: 40px;
      }
    }
  }
  .line{
    margin: 8px 0 20px 0;
    background-color: rgba(150,150,150,1);
    width: 100%;
    height: 1px;
  }
}